/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, forwardRef } from "react";

// @mui material components
import { TypographyProps } from "@mui/material";

// Custom styles for SoftTypography
import SoftTypographyRoot from "components/SoftTypography/SoftTypographyRoot";

interface Props extends TypographyProps {
  color?: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "text" | "white";
  fontWeight?: "light" | "regular" | "medium" | "bold"
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
  verticalAlign?: "unset" | "baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle" | "top" | "bottom";
  textGradient?: boolean;
  children: React.ReactNode;
  opacity?: number;
  [key: string]: any;
}

const SoftTypography: FC<Props> = forwardRef(
  ({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => (
    <SoftTypographyRoot {...rest} ref={ref} ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}>
      {children}
    </SoftTypographyRoot>
  )
);

// Setting default values for the props of SoftTypography
SoftTypography.defaultProps = {
  color: "dark",
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

SoftTypography.displayName = "SoftTypography";

export default SoftTypography;
