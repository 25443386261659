/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, FC } from "react";

// @mui material components
import { BoxProps } from "@mui/material";

// Custom styles for SoftBox
import SoftBoxRoot from "components/SoftBox/SoftBoxRoot";

// declaring props types for MDBox
interface Props extends BoxProps {
  variant?: "contained" | "gradient";
  bgColor?: string;
  capOperation?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  [key: string]: any;
}

const SoftBox: FC<Props> = forwardRef(({ variant, bgColor,capOperation, color, opacity, borderRadius, shadow, ...rest }, ref) => (
  <SoftBoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor,capOperation, color, opacity, borderRadius, shadow }} />
));

// Setting default values for the props of SoftBox
SoftBox.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  capOperation: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
};

SoftBox.displayName = "SoftBox";

export default SoftBox;
