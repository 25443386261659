// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://58d7ae2bf52646c185c25d83221e028b@o1036107.ingest.sentry.io/4504156291727360',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  enabled: process.env.NODE_ENV !== 'development',
  // add to version
  initialScope: {
    tags: {
      version: publicRuntimeConfig?.version,
    },
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});


{/* <button
  type="button"
  onClick={() => {
    throw new Error("Sentry Frontend Error");
  }}
>
  Throw error
</button> */}
