export const Routes = {
  dashboard: '/',
  login: '/login',
  logout: '/logout',
  supplier:'/supplier', 
  refund: {
    ...routesFactory('/refunds'),
  },
};

function routesFactory(endpoint: string) {
  return {
    list: `${endpoint}`,
    create: `${endpoint}/create`,
    edit: (slug: string) => {
      return `${endpoint}/${slug}/edit`;
    },
    translate: (slug: string) => {
      return `${endpoint}/${slug}/translate`;
    },
    details: (slug: string) => `${endpoint}/${slug}`,
  };
}
