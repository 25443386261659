import PrivateRoute from "@/layouts/private-route";
import { NextPageWithLayout } from "@/types";
import { SoftUIControllerProvider } from "@/utils/SoftUIContext";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setUser } from "@sentry/nextjs";
import theme from "assets/theme";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Alert from "components/Alert";
import { enGB, tr } from "date-fns/locale";
import { SessionProvider, useSession } from "next-auth/react";
import { NextIntlProvider } from "next-intl";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";
import { FC, ReactNode, useEffect } from "react";

declare module "notistack" {
  interface VariantOverrides {
    alert: true;
  }
  interface SharedProps {
    color: string;
  }
}

declare module "next-auth" {
  /**
   * Returned by `useSession`, `getSession` and received as a prop on the `SessionProvider` React Context
   */
  interface Session {
    accessToken: string;
    user: {
      id: string;
      name: string;
      email: string;
      role: string;
      company: string;
      companyId: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    breakpoints: typeof breakpoints;
    palette: typeof colors;
    typography: typeof typography;
    boxShadows: typeof boxShadows;
    borders: typeof borders;
    functions: {
      boxShadow: (offset: number[], radius: number[], color: string, opacity: number, inset?: string) => string;
      hexToRgb: (hex: string) => string;
      linearGradient: (color: string, colorState: string, angle?: number) => string;
      pxToRem: (px: number, baseNumber?: number) => string;
      rgba: (color: string, opacity: number) => string;
    };
  }
}

function SentryUserManager() {
  const { data, status } = useSession();

  useEffect(() => {
    if (status === "authenticated") {
      setUser({
        authenticated: true,
        id: data.user.id,
        username: data.user.name,
        email: data.user.email,
        role: data.user.role,
      });
    } else if (status === "unauthenticated") {
      setUser({
        authenticated: false,
      });
    } else {
      setUser(null);
    }
  }, [data, status]);

  return null;
}

const getLocale = (lang: string | undefined) => {
  switch (lang) {
    case "en":
      return enGB;
    case "tr":
      return tr;
    default:
      return enGB;
  }
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const Noop: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

export default function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const { locale, pathname } = useRouter();

  const Layout = (Component as any).Layout || Noop;
  const authRequired = (Component as any).authRequired;

  const messages = require(`../lang/${locale}.json`);

  return (
    <SessionProvider
      session={session}
      refetchInterval={5 * 60} // 5 minutes
      refetchOnWindowFocus={true}
    >
      <Head>
        <title>AVS Next</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <NextIntlProvider
        messages={messages}
        now={new Date()}
        formats={{
          dateTime: {
            short: {
              day: "numeric",
              month: "short",
              year: "numeric",
            },
          },
        }}
      >
        <SoftUIControllerProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(locale)}>
              <SnackbarProvider
                maxSnack={5}
                Components={{
                  alert: Alert,
                }}
                style={{
                  margin: 0,
                }}
                variant="alert"
                color="alert"
              >
                <CssBaseline />
                <SentryUserManager />
                {authRequired ? (
                  <PrivateRoute authProps={authRequired}>
                    <Layout {...pageProps}>
                      <Component {...pageProps} />
                    </Layout>
                  </PrivateRoute>
                ) : (
                  <Layout {...pageProps}>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </SoftUIControllerProvider>
      </NextIntlProvider>
    </SessionProvider>
  );
}
