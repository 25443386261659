import { ButtonProps } from "@mui/material";
import SoftButtonRoot from "components/SoftButton/SoftButtonRoot";
import { FC, forwardRef } from "react";

interface Props extends Omit<ButtonProps, "size" | "variant" | "color"> {
  size?: "small" | "medium" | "large";
  variant?: "text" | "contained" | "outlined" | "gradient";
  color?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  circular?: boolean;
  iconOnly?: boolean;
  [key: string]: any;
}

const SoftButton: FC<Props> = forwardRef(({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => (
  <SoftButtonRoot
    {...rest}
    ref={ref}
    color="primary"
    variant={variant === "gradient" ? "contained" : variant}
    size={size}
    ownerState={{ color, variant, size, circular, iconOnly }}
  >
    {children}
  </SoftButtonRoot>
));

// Setting default values for the props of SoftButton
SoftButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

SoftButton.displayName = "SoftButton";

export default SoftButton;
