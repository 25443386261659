/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for the SoftAlert
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import pxToRem from "assets/theme/functions/pxToRem";
import SoftAlertRoot from "@/components/SoftSnackbarAlert/SoftAlertRoot";

const SoftSnackbarAlert = forwardRef(({ color, children, onClose, ...rest }, ref) => {
  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <SoftAlertRoot
      ownerState={{ color }}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose} sx={{ paddingBottom: pxToRem(9) }}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      mb={0}
      sx={{
        marginBottom: 0,
        maxWidth: "510px",
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </SoftAlertRoot>
  );

  return alertTemplate();
});

// Setting default values for the props of SoftAlert
SoftSnackbarAlert.defaultProps = {
  color: "info",
  dismissible: false,
};

// Typechecking props of the SoftAlert
SoftSnackbarAlert.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "light", "dark"]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
SoftSnackbarAlert.displayName = "SoftSnackbarAlert";
export default SoftSnackbarAlert;
