import { Grid } from "@mui/material";
import lock from "assets/images/illustrations/dark-lock-ill.png";
// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Authentication layout components
import PageLayout from "@/layouts/LayoutContainers/PageLayout";
// NextJS Material Dashboard 2 PRO components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Link from "next/link";

function AccessDeniedPage() {
  const { d1, d3, d4, d5 } = typography;

  return (
    <PageLayout white>
      <SoftBox my={18} height="calc(100vh - 18rem)">
        <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftBox fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }} lineHeight={1.2}>
                <SoftTypography variant="inherit" color={"dark"} textGradient fontWeight="bold">
                  Error 403
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
                Access Denied
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text" opacity={1}>
                  {"You don't have permission to access this page."}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <Link href="/" passHref>
                  <SoftButton variant="gradient" color={"dark"}>
                    go to homepage
                  </SoftButton>
                </Link>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox component="img" src={lock.src} alt="error-404" width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

AccessDeniedPage.getInitialProps = ({ res, err }) => {
  const statusCode = 403;
  return { statusCode };
};

export default AccessDeniedPage;
