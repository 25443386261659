import {
  ADMIN365, AGENT, ARMATEUR, CHARTERER, CUSTOMER,
  POTENTIAL_CUSTOMER, SEASUPPLY_BUYER, SEASUPPLY_GENERAL, SEASUPPLY_SELLER, SUPPLIER
} from './constants';

export const allowedAll = [
  CUSTOMER,
  POTENTIAL_CUSTOMER,
  SUPPLIER,
  ARMATEUR,
  AGENT,
  CHARTERER,
  SEASUPPLY_BUYER,
  SEASUPPLY_SELLER,
  SEASUPPLY_GENERAL,
  ADMIN365,
];

export const customerOnly = [CUSTOMER, POTENTIAL_CUSTOMER];
export const supplierOnly = [SUPPLIER];
export const armateurOnly = [ARMATEUR];
export const admin365Only = [ADMIN365];

export function hasAccess(
  _allowedRoles: [string[]],
  _userPermissions: string[] | undefined | null
) {
  const allowedRoles = _allowedRoles ? _allowedRoles.flat() : [];

  if (_userPermissions) {
    return Boolean(
      allowedRoles?.find((aRole) => _userPermissions.includes(aRole))
    );
  }
  return false;
}

export function getRoleName(role: string) {
  switch (role) {
    case CUSTOMER:
      return 'Customer';
    case POTENTIAL_CUSTOMER:
      return 'Potential Customer';
    case SUPPLIER:
      return 'Supplier';
    case ARMATEUR:
      return 'Armateur';
    case AGENT:
      return 'Agent';
    case CHARTERER:
      return 'Charterer';
    case SEASUPPLY_BUYER:
      return 'SeaSupply Buyer';
    case SEASUPPLY_SELLER:
      return 'SeaSupply Seller';
    case SEASUPPLY_GENERAL:
      return 'SeaSupply General';
    case ADMIN365:
      return 'Admin365';
    default:
      return 'Unknown';
  }
}