// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Authentication layout components
// NextJS Material Dashboard 2 PRO components
import SoftProgress from "@/components/SoftProgress";

import LoginLayout from "@/layouts/LayoutContainers/LoginLayout";
import nhlogo from "assets/images/avs-logo.png";
import pattern from "assets/images/shapes/pattern-lines.svg";

function Loader() {
  // page loader
  const { d1, d3, d4, d5 } = typography;

  return (
    <LoginLayout
      title="Loading..."
      description="Please wait while we load your data."
      color={"info"}
      illustration={{
        image: nhlogo,
        pattern: pattern,
      }}
    >
      <SoftProgress variant="gradient" color="info" />
    </LoginLayout>
  );
}

export default Loader;
