/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Soft UI Dashboard PRO helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { inputColors, dark, text, grey } = colors;
const { borderWidth, borderRadius } = borders;

const datagrid = {
  styleOverrides: {
    root: {
      color: text.main,
      fontSize: pxToRem(14),
      fontWeight: 400,
      fontFamily: typography.fontFamily,
      border: "none",
    },
    toolbar: {
      backgroundColor: dark.main,
    },
    // row cell hover
    row: {
      boxShadow: "none!important",
      "&:hover": {
        backgroundColor: grey[100],
      },
      "&.Mui-selected, &.Mui-selected:hover, &--editing, &--editing .MuiDataGrid-cell": {
        backgroundColor: grey[200],
      },
    },
    cell: {
      outline: "none!important",
      "&--editing .MuiInputBase-root": {
        fontSize: pxToRem(14),
        fontWeight: 400,
        fontFamily: typography.fontFamily,
        padding: `${pxToRem(8)} ${pxToRem(12)} !important`,
        "& .MuiInputBase-input": {
          padding: "0 !important",
          height: pxToRem(22),
        },
      },
    },

    columnHeader: {
      outline: "none!important",
    },

    columnHeaderTitle: {
      fontWeight: 500,
    },

    inputSizeSmall: {
      height: pxToRem(14),
    },
  },
};

export default datagrid;
