/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";

// Custom styles for SoftProgress
import SoftProgressRoot from "components/SoftProgress/SoftProgressRoot";

type SoftProgressProps = {
  variant?: "contained" | "gradient";
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  value?: number;
  label?: boolean;
};

const SoftProgress = forwardRef(({ variant, color, value, label, ...rest }: SoftProgressProps, ref) => (
  <>
    {label && (
      <SoftTypography variant="button" fontWeight="medium" color="text">
        {value}%
      </SoftTypography>
    )}
    <SoftProgressRoot
      {...rest}
      ref={ref}
      variant={value ? "determinate" : "indeterminate"}
      value={value}
      ownerState={{ color, value: value ? value : null, variant }}
    />
  </>
));

// Setting default values for the props of SoftProgress
SoftProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
};

SoftProgress.displayName = "SoftProgress";

export default SoftProgress;
