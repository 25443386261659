/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "@/components/SoftBox";

// Soft UI Dashboard PRO React example components
// import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";

function LoginLayout({ children, color, header, title, description, illustration, menu }) {
  return (
    <SoftBox
      sx={{
        p: 0,
        position: "relative",
      }}
    >
      <Grid container>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <SoftBox pt={3} px={3}>
              {!header ? (
                <>
                  <SoftBox mb={1}>
                    <SoftTypography variant="h4" fontWeight="bold">
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SoftBox
            display={{ xs: "none", lg: "flex" }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            position="relative"
            borderRadius="lg"
            textAlign="center"
            bgColor={color}
            variant="gradient"
            m={2}
            px={13}
            sx={{ overflow: "hidden" }}
          >
            {illustration.pattern && (
              <SoftBox
                component="img"
                src={illustration.pattern.src}
                alt="pattern-lines"
                width="120rem"
                position="absolute"
                topl={0}
                left={0}
                opacity={0.4}
              />
            )}
            {illustration.image && (
              <SoftBox component="img" src={illustration.image.src} alt="chat-illustration" width="100%" maxWidth="31.25rem" />
            )}
            {illustration.title && (
              <SoftBox mt={6} mb={1}>
                <SoftTypography variant="h4" color="black" fontWeight="bold">
                  {illustration.title}
                </SoftTypography>
              </SoftBox>
            )}
            {illustration.description && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="black">
                  {illustration.description}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Setting default values for the props of LoginLayout
LoginLayout.defaultProps = {
  stickyNavbar: false,
  menu: false,
  login: false,
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for LoginLayout
LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
  stickyNavbar: PropTypes.bool,
  menu: PropTypes.bool,
  login: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  illustration: PropTypes.shape({
    image: PropTypes.object,
    pattern: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default LoginLayout;
