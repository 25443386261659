import React, { ReactNode, useEffect } from "react";
import { useRouter } from "next/router";
import { hasAccess } from "@/utils/auth-utils";
import Loader from "@/layouts/Loader";
import { Routes } from "@/utils/routes";
import { useSession } from "next-auth/react";
import AccessDeniedPage from "@/layouts/AccessDeniedPage";

const PrivateRoute: React.FC<{ children: ReactNode; authProps: any }> = ({ children, authProps }) => {
  const router = useRouter();
  // const { token, permissions } = getAuthCredentials();
  const { data, status } = useSession({ required: true });
  const isUser = !!data?.accessToken;

  const hasPermission = !!data?.user.role && hasAccess(authProps, [data?.user.role]);

  useEffect(() => {
    if (!data?.accessToken && status !== "loading") router.replace(Routes.login); // If not authenticated, force log in
  }, [data, status]);

  if (status === "loading") {
    return <Loader />;
  }

  if (isUser && hasPermission) {
    return <>{children}</>;
  }

  return <AccessDeniedPage />;
  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
};

export default PrivateRoute;
