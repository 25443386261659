export const LIMIT = 10;
export const ACCEPTED_FILE_TYPES = "image/*,application/pdf,application/zip,application/vnd.rar,application/epub+zip,.psd"

// 1    Customer
// 2    Potential Customer
// 3    Supplier
// 4    Armateur
// 5    Agent
// 6    Charterer
// 7    SeaSupplyBuyer
// 8    SeaSupplySeller
// 9    SeaSupplyGeneral
// 1000 Admin

export const CUSTOMER = '1';
export const POTENTIAL_CUSTOMER = '2';
export const SUPPLIER = '3';
export const ARMATEUR = '4';
export const AGENT = '5';
export const CHARTERER = '6';
export const SEASUPPLY_BUYER = '7';
export const SEASUPPLY_SELLER = '8';
export const SEASUPPLY_GENERAL = '9';
export const ADMIN365 = '1000';
